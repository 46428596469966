import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// Css
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/magnific-popup/dist/magnific-popup.css';
import './assets/css/plugins/animate.min.css';
import './assets/fonts/flaticon/flaticon.css';
import './assets/css/plugins/font-awesome.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './index.css';

ReactDOM.render(
  <BrowserRouter basename={"/"}>
    <App />
  </BrowserRouter>,
  document.getElementById('Portal Rede Zoe')
);


reportWebVitals();
