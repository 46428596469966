import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home
const Home = React.lazy(() => import("./components/pages/Home"));
// A Igreja
const SobreNos = React.lazy(() => import("./components/pages/SobreNos"));
const Agenda = React.lazy(() => import("./components/pages/Agenda"));
const BibliaOnline = React.lazy(() => import("./components/pages/BibliaOnline"));
const Ministerios = React.lazy(() => import("./components/pages/Ministerios"));
const Ministrydetails = React.lazy(() => import("./components/pages/Ministrydetails"));
const EncontreUmaCelula = React.lazy(() => import("./components/pages/EncontreUmaCelula"));
// Conteudo
const EstudoCelula = React.lazy(() => import("./components/pages/EstudoCelula"));
const MinistracoesAudio = React.lazy(() => import("./components/pages/MinistracoesAudio"));
const MinistracoesDetalhes = React.lazy(() => import("./components/pages/MinistracoesDetalhes"));
// Contato
const Contato = React.lazy(() => import("./components/pages/Contato"));
// Coopere
const Coopere = React.lazy(() => import("./components/pages/Coopere"));
// Paginas
const PaginaInterna = React.lazy(() => import("./components/pages/PaginaInterna"));
const IgrejasRedeZoe = React.lazy(() => import("./components/pages/IgrejasRedeZoe"));
// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})


function App() {
  return (
    <Router basename={"/"}>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Preloader />
          <Switch>
          <Route exact path="/links" component={() => {
              window.location.href = "https://linktr.ee/cczoe";
              return null;
            }} />
            <Route exact path="/ebooks" component={() => {
              window.location.href = "https://www.amazon.com.br/s?i=digital-text&rh=p_27%3ACesinha+Sitta&s=relevancerank&text=Cesinha+Sitta&ref=dp_byline_sr_ebooks_1";
              return null;
            }} />
            {/* Home */}
            <Route exact path="/" component={Home} />
            {/* A Igreja */}
            <Route exact path="/sobre-nos" component={SobreNos} />
            <Route exact path="/agenda" component={Agenda} />
            <Route exact path="/encontre-uma-celula" component={EncontreUmaCelula} />
            <Route exact path="/igrejas/:path" component={IgrejasRedeZoe} />
            {/* Conteudo */}
            <Route exact path="/estudo-de-celula" component={EstudoCelula} />
            <Route exact path="/ministracoes" component={MinistracoesAudio} />
            <Route exact path="/ministracoes-detalhes/:id" component={props => (<MinistracoesDetalhes {...props} key={window.location.pathname} />)} />
            {/* Contato */}
            <Route exact path="/contato" component={Contato} />
            {/* Coopere */}
            <Route exact path="/como-cooperar" component={Coopere} />
            {/* Pagina */}
            <Route exact path="/paginas/:path" component={props => (<PaginaInterna {...props} key={window.location.pathname} />)} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
